/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Bumble प्राप्त करें",
      "सभी को स्वीकार करें",
      "हम अपनी साइट को बेहतर बनाने के लिए कुकीज़ का उपयोग करते हैं। इसमें एनालिटिक्स कुकीज़ और विज्ञापन कुकीज़ शामिल हैं। अधिक जानकारी के लिए, कृपया हमारी <a p0>कुकी नीति</a> देखें।",
      "विकल्प प्रबंधित करें",
      "हमें बता दें कि आप कुकीज़ से सहमत हैं",
      "और पढ़ें",
      "वापस जाएँ",
      "ग़लत फोन क्रमांक",
      "© {{current_year}} Bumble | सभी अधिकार सुरक्षित हैं",
      "श्रेणियां",
      "सबसे हालिया",
      "Bumble Buzz",
      "Buzz अब एक नई वेबसाइट पर चला गया है। डेटिंग, दोस्ती, स्वास्थ्य और बहुत कुछ पर सलाह के लिए इसे यहां देखें: <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "Buzz अब नई जगह पर है!",
      "Bumble में करिअर",
      "मेरी निजी जानकारी न बेचें या न शेयर करें",
      "@{{account_name}} को फॉलो करें",
      "कैरियर",
      "दोस्ती",
      "प्यार",
      "कोई कहानी जमा करें",
      "सफलता की कहानियाँ",
      "कल्याण",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble डेट",
      "करियर",
      "कंपनी",
      "इवेंट्स",
      "नये, सशक्त लोगों से मिलने के लिए इससे बेहतर समय कभी भी नहीं आया है।",
      "Bumble को फॉलो करें",
      "दिशानिर्देश",
      "सहायता केंद्र",
      "कुकीज़ प्रबंधित करें",
      "गोपनीयता नीति",
      "ऐप",
      "नियम और शर्तें",
      "हमारे बारे में",
      "एम्बेसडर",
      "The Buzz",
      "शॉप",
      "ऐप डाउनलोड करें",
      "Android के लिए डाउनलोड करें",
      "iOS के लिए डाउनलोड करें",
      "हमें खेद है, पर आप जिस पेज को खोज रहे हैं हम उसे खोज नहीं सके हैं।",
      "साइन इन करें",
      "शामिल हो जाएँ",
      "समुदाय में शामिल हों!",
      "सभी देखें →",
      "↓ और देखो",
      "अरे रे! कुछ गड़बड़ हो गई है"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "दिन",
                  "दिनों"
            ],
            "singular": [
                  "दिन",
                  "दिन"
            ]
      },
      "hour": {
            "plural": [
                  "घंटे",
                  "घंटे"
            ],
            "singular": [
                  "घंटे",
                  "घंटे"
            ]
      },
      "minute": {
            "plural": [
                  "मिनट",
                  "मिनटों"
            ],
            "singular": [
                  "मिनट",
                  "मिनट"
            ]
      },
      "month": {
            "plural": [
                  "महीने",
                  "महीनों"
            ],
            "singular": [
                  "महीना",
                  "महीना"
            ]
      },
      "second": {
            "plural": [
                  "सेकंड",
                  "सेकंड"
            ],
            "singular": [
                  "सेकंड",
                  "सेकंड"
            ]
      },
      "year": {
            "plural": [
                  "साल",
                  "सालों"
            ],
            "singular": [
                  "साल",
                  "साल"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
